<template>
  <!-- Begin page -->
    <div v-if="loading" class="loading-overlay">
        <div class="loading-text">Загрузка...</div>
    </div>
    <div class="main-content">
        <HeaderTemplate :user="user" :dashName="dashName" :startApps="startApps"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">

                <!-- start page title -->
                        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Админка</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a href="/">{{ dashName }}</a></li>
                            <li class="breadcrumb-item active">Админка</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="mb-4">
                    <span class="badge badge-soft-primary float-right">День</span>
                    <h5 class="card-title mb-0">Профит</h5>
                </div>
                <div class="row d-flex align-items-center mb-4">
                    <div class="col-8">
                        <h2 class="d-flex align-items-center mb-0 user-day-balance">
                            ${{userStartStatistic.dayBalance}}
                        </h2>
                    </div>
                    <div class="col-4 text-right">
                                                <span class="text-muted user-day-eps">${{userStartStatistic.dayEpc}}</span>
                    </div>
                </div>

                <div class="progress shadow-sm" style="height: 5px;">
                    <div
                    class="progress-bar"
                    :class="{'bg-danger': userStartStatistic.dayLoading <= 10,
                        'bg-warning': (userStartStatistic.dayLoading > 10 && userStartStatistic.dayLoading <= 20),
                        'bg-info': (userStartStatistic.dayLoading > 20 && userStartStatistic.dayLoading <= 30),
                        'bg-success': userStartStatistic.dayLoading > 30}"
                    role="progressbar user-day-eps-progress" v-bind:style="{width: (userStartStatistic.dayLoading < 100 ? userStartStatistic.dayLoading : 100) + '%'}">
                    </div>
                </div>
            </div>
            <!--end card body-->
        </div><!-- end card-->
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="mb-4">
                    <span class="badge badge-soft-primary float-right">Неделя</span>
                    <h5 class="card-title mb-0">Профит</h5>
                </div>
                <div class="row d-flex align-items-center mb-4">
                    <div class="col-8">
                        <h2 class="d-flex align-items-center mb-0 user-week-balance">
                            ${{userStartStatistic.weekBalance}}
                        </h2>
                    </div>
                    <div class="col-4 text-right">
                                                <span class="text-muted user-week-eps">${{userStartStatistic.weekEpc}}</span>
                    </div>
                </div>

                <div class="progress shadow-sm" style="height: 5px;">
                    <div
                    class="progress-bar"
                    :class="{'bg-danger': userStartStatistic.weekLoading <= 10,
                        'bg-warning': (userStartStatistic.weekLoading > 10 && userStartStatistic.weekLoading <= 20),
                        'bg-info': (userStartStatistic.weekLoading > 20 && userStartStatistic.weekLoading <= 30),
                        'bg-success': userStartStatistic.weekLoading > 30}"
                    role="progressbar user-week-eps-progress" v-bind:style="{width: (userStartStatistic.weekLoading < 100 ? userStartStatistic.weekLoading : 100) + '%'}">
                    </div>
                </div>
            </div>
            <!--end card body-->
        </div><!-- end card-->
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="mb-4">
                    <span class="badge badge-soft-primary float-right">Месяц</span>
                    <h5 class="card-title mb-0">Профит</h5>
                </div>
                <div class="row d-flex align-items-center mb-4">
                    <div class="col-8">
                        <h2 class="d-flex align-items-center mb-0 user-month-balance">
                            ${{userStartStatistic.monthBalance}}
                        </h2>
                    </div>
                    <div class="col-4 text-right">
                                                <span class="text-muted user-month-eps">${{userStartStatistic.monthEpc}}</span>
                    </div>
                </div>

                <div class="progress shadow-sm" style="height: 5px;">
                    <div
                    class="progress-bar"
                    :class="{'bg-danger': userStartStatistic.monthLoading <= 10,
                        'bg-warning': (userStartStatistic.monthLoading > 10 && userStartStatistic.monthLoading <= 20),
                        'bg-info': (userStartStatistic.monthLoading > 20 && userStartStatistic.monthLoading <= 30),
                        'bg-success': userStartStatistic.monthLoading > 30}"
                    role="progressbar user-month-eps-progress" v-bind:style="{width: (userStartStatistic.monthLoading < 100 ? userStartStatistic.monthLoading : 100) + '%'}">
                    </div>
                </div>
            </div>
            <!--end card body-->
        </div>
        <!--end card-->
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="mb-4">
                    <span class="badge badge-soft-primary float-right">День</span>
                    <h5 class="card-title mb-0">Трафик</h5>
                </div>
                <div class="row d-flex align-items-center mb-4">
                    <div class="col-8">
                        <h2 class="d-flex align-items-center mb-0 user-day-traffic">
                            {{userStartStatistic.dayHit}}/{{userStartStatistic.dayHost}}
                        </h2>
                    </div>
                    <div class="col-4 text-right">
                                                <span class="text-muted user-day-traffic-eps">{{userStartStatistic.dayTrafEpc}}%</span>
                    </div>
                </div>

                <div class="progress shadow-sm" style="height: 5px;">
                    <div class="progress-bar bg-info" role="progressbar user-day-traffic-progress" v-bind:style="{width: (userStartStatistic.dayTrafLoader < 100 ? userStartStatistic.dayTrafLoader : 100) + '%'}"></div>
                </div>
            </div>
            <!--end card body-->
        </div><!-- end card-->
    </div> <!-- end col-->
</div>
<!-- end row-->

<div class="row">
    <div class="col-lg-6">

        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="card-title">Аналитика трафика</h4>
                        <p class="card-subtitle mb-4">последние 30 дней</p>
                        <div ref="morrBar" id="morris-bar-example" class="morris-chart"></div>
                    </div>
                </div>
            </div>
            <!--end card body-->
        </div> <!-- end card-->
    </div> <!-- end col -->

    <div class="col-lg-6">

        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Последние заказы</h4>
                <p class="card-subtitle mb-4 font-size-13">
                    Последние 5 заказов
                </p>

                <div class="table-responsive">
                    <table class="table table-centered table-striped table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th>Покупатель</th>
                            <th>Локация</th>
                            <th>Сайт/Апсел</th>
                            <th>Сумма</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(val, key) in topOrders" :key="key">
                            <td class="table-user">
                                <img
                                :src="val.status > 0 ? '/assets/images/animated/money.gif' : '/assets/images/animated/hourglass.gif'"
                                :alt="val.status > 0 ? 'оплачен' : 'ожидание'"
                                class="mr-2 avatar-xs rounded-circle">
                                <a href="javascript:void(0);" class="text-body font-weight-semibold">{{val.customerName}}</a>
                            <br>
                                <small>{{val.customerEmail}}</small>
                            </td>
                            <td>
                              <i v-if="val.customerInfo" @click="viewCustomer(val.customerInfo)" class="mdi mdi-information-outline cursor"></i>
                              {{val.customerInfo ? JSON.parse(val.customerInfo).products ? JSON.parse(val.customerInfo).products.ipInfo.data.v4.geolocation.country.name : 'undefined' : 'undefined'}}
                            </td>
                            <td>
                                {{val.offerId}}/{{parseInt(val.upselId) + 1}}
                            </td>
                            <td>
                                {{val.amount}}
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>

            </div>
            <!--end card body-->

        </div>
        <!--end card-->

    </div><!-- end col -->

</div>
<!--end row-->
                <!--end row-->

            </div> <!-- container-fluid -->
        </div>
        <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2020 © Xeloro.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            Design & Develop by Myra
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

</div>
<!-- END layout-wrapper -->

<!-- Overlay-->
<div class="menu-overlay"></div>
</template>

<script>

import wsMixin from '@/mixins/websocket.js'
import HeaderTemplate from '@/components/HeaderTemplate.vue'
import Swal from 'sweetalert2'
// import md5 from 'md5'

export default {
  name: 'ServiceStartView',
  mixins: [wsMixin],
  components: {
    HeaderTemplate
  },
  data () {
    return {
      startApps: true,
      loading: true,
      onMessage: null,
      user: {},
      userId: localStorage.getItem('uid'),
      userSid: localStorage.getItem('sid'),
      userStartStatistic: [],
      morrisBarExample: [],
      topOrders: [],
      wsParam: {},
      createScript: null,
      insertScriptMorris: null,
      insertScriptRafael: null,
      insertScript: null,
      checkConnected: true,
      timer: ''
    }
  },
  created () {
    this.connectNew()
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.insertScriptMorris = document.createElement('script')
        this.insertScriptMorris.setAttribute('src', 'plugins/morris-js/morris.min.js')
        document.body.appendChild(this.insertScriptMorris)
        this.insertScriptRafael = document.createElement('script')
        this.insertScriptRafael.setAttribute('src', 'plugins/raphael/raphael.min.js')
        document.body.appendChild(this.insertScriptRafael)
        this.insertScript = document.createElement('script')
        this.insertScript.setAttribute('src', 'assets/js/includes/home.js')
        document.body.appendChild(this.insertScript)
        this.sendApi()
      }, 200)
    })
  },
  beforeUnmount () {
    if (this.insertScriptMorris !== null) {
      document.body.removeChild(this.insertScriptMorris)
    }
    if (this.insertScriptRafael !== null) {
      document.body.removeChild(this.insertScriptRafael)
    }
    if (this.insertScript !== null) {
      document.body.removeChild(this.insertScript)
    }
    if (this.createScript !== null) {
      document.body.removeChild(this.createScript)
    }
    this.checkConnected = false
    this.connection.close()
    clearTimeout(this.timer)
  },
  methods: {
    sendApi () {
      this.sendMessage({
        event: '/service',
        param: this.wsParam,
        userId: this.userId,
        sid: this.userSid
      })
      this.timer = setTimeout(() => {
        this.sendApi()
      }, 5000)
    },
    viewCustomer (data) {
      data = JSON.parse(data)
      const text = JSON.stringify(data, null, 2)
      Swal.fire({
        type: 'info',
        html: `<pre style="text-align: left !important;">${text}</pre>`,
        confirmButtonClass: 'btn btn-confirm mt-2'
      })
    },
    morrisBarScript (data) {
      if (this.createScript !== null) {
        document.body.removeChild(this.createScript)
      }
      this.createScript = document.createElement('script')
      this.createScript.append(`if ($("#morris-bar-example").length) { setMorrisB(${data}) }`)
      document.body.appendChild(this.createScript)
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
        if (this.onMessage.error) {
          localStorage.clear()
          location.href = '/'
        }
        switch (this.onMessage.event) {
          case 'api':
            this.sendApi()
            break
          case '/service':
            this.user = JSON.parse(this.onMessage.userData)
            this.userStartStatistic = this.onMessage.startStatistic
            this.topOrders = JSON.parse(this.onMessage.topOrders)
            this.morrisBarExample = this.onMessage.morrisBarExample
            this.morrisBarScript(this.morrisBarExample)
            if (this.loading) {
              this.loading = false
            }
            setTimeout(() => {
              this.startApps = false
            }, 1000)
            break
          default:
            break
        }
      }

      this.connection.onclose = (e) => {
        if (this.checkConnected) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
          this.connectNew()
        } else {
          console.log('Socket is closed. beforeUnmount')
        }
      }
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    }
  }
}
</script>
