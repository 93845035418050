<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <!-- LOGO -->
      <div class="navbar-brand-box d-flex align-items-left">
        <a href="/" class="logo">
          <i class="mdi mdi-album"></i>
          <span>
              {{ dashName }}
          </span>
        </a>
        <button type="button" class="btn btn-sm mr-2 font-size-16 d-lg-none header-item waves-effect waves-light" data-toggle="collapse" data-target="#topnav-menu-content">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <div class="dropdown d-inline-block ml-2">
          <button type="button" class="btn header-item waves-effect waves-light"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user" src="/assets/images/users/avatar-3.jpg"
            alt="Header Avatar">
            <span class="d-sm-inline-block ml-1">{{ user.login }} (USD {{ balance }})</span>
            <i class="mdi mdi-chevron-down d-sm-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link to="/payouts" class="dropdown-item d-flex align-items-center justify-content-between"><span>Выплаты (USD {{ user.balance }})</span></router-link>
            <a @click="exitApp" href="/" class="dropdown-item d-flex align-items-center justify-content-between">
              <span>Выход</span>
            </a>
          </div>
        </div>
      </div>
    </div>
</header>

<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
      <div class="collapse navbar-collapse" id="topnav-menu-content">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" activeClass="active" class="nav-link"><i class="mdi mdi-home-analytics"></i>Дашборд</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/statistic" activeClass="active" class="nav-link"><i class="mdi mdi-equalizer-outline"></i>Статистика</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/orders" activeClass="active" class="nav-link"><i class="mdi mdi-book-play-outline"></i>Заказы</router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link to="/offers" activeClass="active" class="nav-link"><i class="mdi mdi-diamond-stone"></i>Офферы</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/payouts" activeClass="active" class="nav-link"><i class="mdi mdi-cash-refund"></i>Выплаты</router-link>
          </li>
        </ul>
        <ul v-if="user.role > 0" class="navbar-nav">
          <li class="nav-item">
            <router-link v-if="user.role > 1" to="/serviceDash" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-home-analytics"></i>Админка</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="user.role > 1" to="/serviceStatistic" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-equalizer-outline"></i>Статистика</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="user.role > 1" to="/serviceOrders" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-book-play-outline"></i>Заказы</router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link to="/serviceOffers" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-diamond-stone"></i>Офферы</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="user.role > 1" to="/servicePayouts" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-cash-refund"></i>Выплаты</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="user.role > 1" to="/serviceUsers" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-cash-refund"></i>Пользователи</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="user.role > 1" to="/serviceEngine" activeClass="active text-dark" class="nav-link text-warning"><i class="mdi mdi-cash-refund"></i>Движок</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
</template>

<script>
export default {
  name: 'HeaderTemplate',
  data () {
    return {
      balance: 0,
      AUDIO: {
        BALANCE: new Audio(require('@/assets/media/money.mp3')),
        PAYOUT: new Audio(require('@/assets/media/monetka.mp3'))
      }
    }
  },
  props: {
    user: Object,
    dashName: String,
    startApps: Boolean
  },
  watch: {
    user: function (val) {
      this.audioSet()
    }
  },
  methods: {
    audioSet () {
      if (!this.startApps) {
        if (this.user.balance > this.balance) {
          this.AUDIO.BALANCE.play()
        }
        if (this.user.balance < this.balance) {
          this.AUDIO.PAYOUT.play()
        }
      }
      this.balance = this.user.balance
    },
    exitApp () {
      localStorage.clear()
      location.href = '/'
    }
  }
}
</script>
