<template>
  <!-- Begin page -->
    <div v-if="loading" class="loading-overlay">
        <div class="loading-text">Загрузка...</div>
    </div>
        <HeaderTemplate :user="user" :dashName="dashName" :startApps="startApps"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Заказы</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a href="javascript: void(0);">{{ dashName }}</a></li>
                            <li class="breadcrumb-item active">Заказы</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title"></h4>
                        <p class="card-subtitle mb-4">
                            <code></code>
                        </p>
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                <tr>
                                  <th>СТАТУС</th>
                                  <th>СУММА</th>
                                  <th>САЙТ/АПСЕЛ</th>
                                  <th>ПОКУПАТЕЛЬ</th>
                                  <th>ИНФОРМАЦИЯ</th>
                                  <th>
                                    <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                      <button @click="ascDesc = ascDesc === 'ASC' ? 'DESC' : 'ASC', wsParam = {orderBy: 'id', where: false, ascDesc: ascDesc, limit: false, offset: false}" type="button" class="btn btn-outline-dark waves-effect waves-light wsParam">ID</button>
                                      <button @click="ascDesc = ascDesc === 'ASC' ? 'DESC' : 'ASC', wsParam = {orderBy: 'updatedAt', where: false, ascDesc: ascDesc, limit: false, offset: false}" type="button" class="btn btn-outline-dark waves-effect waves-light wsParam">Изменен</button>
                                      <button @click="wsParam = {orderBy: 'id', where: 'status > 0', ascDesc: false, limit: false, offset: false}" type="button" class="btn btn-outline-dark waves-effect waves-light wsParam">Оплачен</button>
                                    </div>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(val, key) in userStatistic" :key="key">
                                  <td class="table-user">
                                    <img
                                        :src="val.status > 0 ? '/assets/images/animated/money.gif' : '/assets/images/animated/hourglass.gif'"
                                        :alt="val.status > 0 ? 'оплачен' : 'ожидание'"
                                        class="mr-2 avatar-xs rounded-circle">
                                  </td>
                                  <th>${{val.amount}}</th>
                                  <td>{{val.offerId}}/{{val.upselId * 1 + 1}}</td>
                                  <td>
                                      <a href="javascript:void(0);" class="text-body font-weight-semibold">{{val.customerName}}</a>
                                      <br>
                                      <small>{{val.customerEmail}}</small>
                                  </td>
                                  <td>
                                    <i v-if="val.customerInfo" @click="viewCustomer(val.customerInfo)" class="mdi mdi-information-outline cursor"></i>
                                    {{val.customerInfo ? JSON.parse(val.customerInfo).products ? JSON.parse(val.customerInfo).products.ipInfo.data.v4.geolocation.country.name : 'undefined' : 'undefined'}}
                                  </td>
                                  <td>
                                    {{val.id}}-{{val.orderId}}
                                    <br>
                                    <small>{{val.createdAt}} (обновлен {{val.updatedAt}})</small>
                                  </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item">
                                    <button class="page-link waves-effect" @click="movePages(-1)">◄</button>
                                </li>
                                <li class="page-item"><button class="page-link waves-effect" @click="movePages(-1)">{{startRow === 0 ? '' : startRow}}</button></li>
                                <li class="page-item active"><button class="page-link waves-effect" >{{startRow + 1}}</button></li>
                                <li class="page-item"><button class="page-link waves-effect" @click="movePages(1)">{{startRow + 2}}</button></li>
                                <li class="page-item">
                                    <button class="page-link waves-effect" @click="movePages(1)">►</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card -->

            </div>
            <!-- end col -->
        </div><!-- end row-->

            </div> <!-- container-fluid -->
        </div>
        <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2020 © Xeloro.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            Design & Develop by Myra
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

<!-- END layout-wrapper -->

<!-- Overlay-->
<div class="menu-overlay"></div>
</template>

<script>

import wsMixin from '../mixins/websocket.js'
import HeaderTemplate from '@/components/HeaderTemplate.vue'
import Swal from 'sweetalert2'
// import md5 from 'md5'

export default {
  name: 'OrdersView',
  mixins: [wsMixin],
  components: {
    HeaderTemplate
  },
  data () {
    return {
      startApps: true,
      loading: true,
      onMessage: null,
      user: {},
      userId: localStorage.getItem('uid'),
      userSid: localStorage.getItem('sid'),
      userAllStatistic: [],
      userStatistic: [],
      userRowStatistic: [],
      startRow: 0,
      rowsPerPage: 10,
      createScript: null,
      orderBy: '',
      wsParam: { orderBy: false, where: false, ascDesc: false, limit: false, offset: false },
      checkConnected: true
    }
  },
  created () {
    this.connectNew()
  },
  mounted () {
    // const insertScript = document.createElement('script')
    // insertScript.setAttribute('src', 'assets/js/includes/home.js')
    // document.body.appendChild(insertScript)
    setTimeout(() => {
      this.sendApi()
    }, 200)
  },
  beforeUnmount () {
    this.checkConnected = false
    this.connection.close()
  },
  watch: {
    wsParam: function (val) {
      this.sendApi()
    }
  },
  methods: {
    sendApi () {
      this.sendMessage({
        event: '/orders',
        param: JSON.stringify(this.wsParam),
        userId: this.userId,
        sid: this.userSid
      })
    },
    movePages (index) {
      const newStartRow = this.startRow + index
      if (newStartRow >= 0) {
        this.startRow = newStartRow
      }
      const offset = this.startRow > 0 ? this.startRow * this.rowsPerPage : 0
      this.wsParam.limit = this.rowsPerPage
      this.wsParam.offset = offset
      this.sendApi()
    },
    viewCustomer (data) {
      data = JSON.parse(data)
      const text = JSON.stringify(data, null, 2)
      Swal.fire({
        type: 'info',
        html: `<pre style="text-align: left !important;">${text}</pre>`,
        confirmButtonClass: 'btn btn-confirm mt-2'
      })
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
        if (this.onMessage.error) {
          localStorage.clear()
          location.href = '/'
        }
        switch (this.onMessage.event) {
          case 'api':
            this.sendApi()
            break
          case '/orders':
            this.user = JSON.parse(this.onMessage.userData)
            this.userStatistic = this.onMessage.userOrders
            this.userRowStatistic = this.userStatistic
            // .slice(this.startRow, this.rowsPerPage + this.startRow)
            if (this.loading) {
              this.loading = false
            }
            setTimeout(() => {
              this.startApps = false
            }, 1000)
            break
          default:
            break
        }
      }

      this.connection.onclose = (e) => {
        if (this.checkConnected) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
          this.connectNew()
        } else {
          console.log('Socket is closed. beforeUnmount')
        }
      }
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    }
  }
}
</script>
