<template>
  <router-view/>
</template>

<script>
// import wsMixin from './mixins/websocket.js'

export default {
  // mixins: [wsMixin],
  data () {
    return {
      user: 156515654
      // wsConnect: 'wss://ws.cloud-bin-pay.pro',
      // connection: null
    }
  }
}
</script>

<style>
  .cursor {
    cursor: pointer;
  }
  .cursor:hover {
    background-color: #e2e2e2;
    color: black;
  }
</style>
