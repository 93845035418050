<template>
  <!-- Begin page -->
    <div v-if="loading" class="loading-overlay">
        <div class="loading-text">Загрузка...</div>
    </div>
        <HeaderTemplate :user="user" :dashName="dashName" :startApps="startApps"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
          <div class="container-fluid">
              <!-- start page title -->
              <div class="row">
                  <div class="col-12">
                      <div class="page-title-box d-flex align-items-center justify-content-between">
                          <h4 class="mb-0 font-size-18">Движок</h4>

                          <div class="page-title-right">
                              <ol class="breadcrumb m-0">
                                  <li class="breadcrumb-item"><a href="javascript: void(0);">{{ dashName }}</a></li>
                                  <li class="breadcrumb-item active">Движок</li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- end page title -->

              <div class="row">
                  <div class="col-md-12">
                      <div class="card">
                          <div class="card-body">
                              <div class="clearfix">
                                  <div class="float-left">
                                      <h4><b>Все изменения делать очень аккуратно!</b></h4>
                                  </div>
                                  <div class="float-right">
                                      <h4 class="m-0 d-print-none"></h4>
                                  </div>
                              </div>

                              <div class="row">
                                  <div class="col-md-12">
                                    <p>{{ themeColor === 0 ? 'Светлая' : 'Темная' }} тема</p>
                                    <input v-model="themeColor" min="0" max="1" type="number" class="form-control">
                                    <br>
                                    <p>Домен редиректов публичный (для редиректов и на оплату)</p>
                                    <input v-model="publicDomain" type="text" class="form-control">
                                    <br>
                                    <p>Серверный домен (АПИ домен)</p>
                                    <input v-model="paymentDomain" type="text" class="form-control">
                                    <br>
                                    <p>BTC домен</p>
                                    <input v-model="btcDomain" type="text" class="form-control">
                                    <br>
                                    <p>Апи ключ</p>
                                    <input v-model="storeId" type="text" class="form-control">
                                    <br>
                                    <br>
                                    <button @click="updateEngine()" class="btn btn-primary btn-block  px-4">Сохранить</button>
                                  </div>
                              </div>
                          </div>
                      </div> <!-- end card-->
                  </div> <!-- end col -->
              </div>
              <!-- end row -->
          </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2020 © Xeloro.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            Design & Develop by Myra
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

<!-- END layout-wrapper -->

<!-- Overlay-->
<div class="menu-overlay"></div>
<!-- SWALL -->
</template>

<script>

import wsMixin from '../mixins/websocket.js'
import HeaderTemplate from '@/components/HeaderTemplate.vue'
// import md5 from 'md5'

export default {
  name: 'ServiceEngine',
  mixins: [wsMixin],
  components: {
    HeaderTemplate
  },
  data () {
    return {
      startApps: true,
      loading: true,
      onMessage: null,
      user: {},
      userId: localStorage.getItem('uid'),
      userSid: localStorage.getItem('sid'),
      themeColor: 0,
      publicDomain: '',
      paymentDomain: '',
      btcDomain: '',
      storeId: '',
      wsParam: {}
    }
  },
  created () {
    this.connectNew()
  },
  mounted () {
    setTimeout(() => {
      this.sendApi()
    }, 200)
  },
  beforeUnmount () {
    this.checkConnected = false
    this.connection.close()
  },
  methods: {
    sendApi () {
      this.sendMessage({
        event: '/serviceEngine',
        param: JSON.stringify(this.wsParam),
        userId: this.userId,
        sid: this.userSid
      })
    },
    updateEngine () {
      this.wsParam = {
        event: 'engineUpdate',
        publicDomain: this.publicDomain,
        paymentDomain: this.paymentDomain,
        btcDomain: this.btcDomain,
        storeId: this.storeId,
        theme: this.themeColor
      }
      this.sendApi()
      this.wsParam = {}
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
        if (this.onMessage.error) {
          localStorage.clear()
          location.href = '/'
        }
        switch (this.onMessage.event) {
          case 'api':
            this.sendApi()
            break
          case '/serviceEngine':
            this.user = JSON.parse(this.onMessage.userData)
            this.publicDomain = this.onMessage.publicDomain
            this.paymentDomain = this.onMessage.paymentDomain
            this.btcDomain = this.onMessage.btcDomain
            this.storeId = this.onMessage.storeId
            this.themeColor = this.onMessage.theme
            if (this.loading) {
              this.loading = false
            }
            setTimeout(() => {
              this.startApps = false
            }, 1000)
            break
          default:
            break
        }
      }

      this.connection.onclose = (e) => {
        if (this.checkConnected) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
          this.connectNew()
        } else {
          console.log('Socket is closed. beforeUnmount')
        }
      }
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    }
  }
}
</script>
