<template>
  <!-- Begin page -->
    <div v-if="loading" class="loading-overlay">
        <div class="loading-text">Загрузка...</div>
    </div>
    <div class="main-content">
        <HeaderTemplate :user="user" :dashName="dashName" :startApps="startApps"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">

                <!-- start page title -->
                <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Админка Партнеры</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a href="javascript: void(0);">{{ dashName }}</a></li>
                            <li class="breadcrumb-item active">Админка Партнеры</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title"></h4>
                        <p class="card-subtitle mb-4">
                            <code></code>
                        </p>
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                <tr>
                                    <th class="cursor">ID/ЛОГИН</th>
                                    <th class="cursor">ТЕЛЕГРАМ</th>
                                    <th class="cursor">БАЛАНС</th>
                                    <th class="cursor">ПРОЦЕНТ</th>
                                    <th class="cursor">СТАТУС</th>
                                    <th class="cursor">РОЛЬ</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(val, key) in users" :key="key">
                                    <th>
                                      <div v-if="btnUser[key + 'login']" class="form-group form-inline">
                                        <input v-model="val.login" class="form-control-sm" type="text">
                                        <button @click="updateUser(key)" class="btn btn-sm btn-primary waves-effect waves-light">Сохранить</button>
                                      </div>
                                      <span @click="btnUser[key + 'login'] = !btnUser[key + 'login']" class="cursor">{{val.id}}. {{val.login}}</span>
                                    </th>
                                    <td>
                                      <div v-if="btnUser[key + 'telegram']" class="form-group form-inline">
                                        <input v-model="val.telegram" class="form-control-sm" type="text">
                                        <button @click="updateUser(key)" class="btn btn-sm btn-primary waves-effect waves-light">Сохранить</button>
                                      </div>
                                      <span @click="btnUser[key + 'telegram'] = !btnUser[key + 'telegram']" class="cursor">{{val.telegram}}</span>
                                    </td>
                                    <td>
                                      <div v-if="btnUser[key + 'balance']" class="form-group form-inline">
                                        <input v-model="val.balance" class="form-control-sm" type="number">
                                        <button @click="updateUser(key)" class="btn btn-sm btn-primary waves-effect waves-light">Сохранить</button>
                                      </div>
                                      <span @click="btnUser[key + 'balance'] = !btnUser[key + 'balance']" class="cursor">{{val.balance}} $</span>
                                    </td>
                                    <td>
                                      <div v-if="btnUser[key + 'userPercent']" class="form-group form-inline">
                                        <input v-model="val.userPercent" class="form-control-sm" type="number">
                                        <button @click="updateUser(key)" class="btn btn-sm btn-primary waves-effect waves-light">Сохранить</button>
                                      </div>
                                      <span @click="btnUser[key + 'userPercent'] = !btnUser[key + 'userPercent']" class="cursor">{{val.userPercent}} %</span>
                                    </td>
                                    <td>
                                      <div v-if="btnUser[key + 'active']" class="form-group form-inline">
                                        <input v-model="val.active" min="0" max="1" class="form-control-sm" type="number">
                                        <button @click="updateUser(key)" class="btn btn-sm btn-primary waves-effect waves-light">Сохранить</button>
                                      </div>
                                      <span @click="btnUser[key + 'active'] = !btnUser[key + 'active']" class="cursor">{{val.active === 0 ? 'Неактивен' : 'Активен'}}</span>
                                    </td>
                                    <td>
                                      <div v-if="btnUser[key + 'role']" class="form-group form-inline">
                                        <input v-model="val.role" min="0" max="2" class="form-control-sm" type="number">
                                        <button @click="updateUser(key)" class="btn btn-sm btn-primary waves-effect waves-light">Сохранить</button>
                                      </div>
                                      <span @click="btnUser[key + 'role'] = !btnUser[key + 'role']" class="cursor">{{ userRole[val.role] }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item">
                                    <button class="page-link waves-effect" @click="movePages(-1)">◄</button>
                                </li>
                                <li class="page-item"><button class="page-link waves-effect" @click="movePages(-1)">{{startRow === 0 ? '' : startRow}}</button></li>
                                <li class="page-item active"><button class="page-link waves-effect" >{{startRow + 1}}</button></li>
                                <li class="page-item"><button class="page-link waves-effect" @click="movePages(1)">{{startRow + 2}}</button></li>
                                <li class="page-item">
                                    <button class="page-link waves-effect" @click="movePages(1)">►</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card -->

            </div>
            <!-- end col -->
        </div><!-- end row-->

            </div> <!-- container-fluid -->
        </div>
        <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2020 © Xeloro.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            Design & Develop by Myra
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

</div>
<!-- END layout-wrapper -->

<!-- Overlay-->
<div class="menu-overlay"></div>
</template>

<script>

import wsMixin from '../mixins/websocket.js'
import HeaderTemplate from '@/components/HeaderTemplate.vue'
// import Swal from 'sweetalert2'
// import md5 from 'md5'

export default {
  name: 'ServiceUsers',
  mixins: [wsMixin],
  components: {
    HeaderTemplate
  },
  data () {
    return {
      startApps: true,
      loading: true,
      onMessage: null,
      user: {},
      userId: localStorage.getItem('uid'),
      userSid: localStorage.getItem('sid'),
      users: {},
      startRow: 0,
      rowsPerPage: 10,
      createScript: null,
      wsParam: {},
      checkConnected: true,
      btnUser: [],
      userRole: ['Партнер', 'Модератор', 'Админ']
    }
  },
  created () {
    this.connectNew()
  },
  mounted () {
    setTimeout(() => {
      this.sendApi()
    }, 200)
  },
  beforeUnmount () {
    this.checkConnected = false
    this.connection.close()
  },
  methods: {
    sendApi () {
      this.sendMessage({
        event: '/serviceUsers',
        param: JSON.stringify(this.wsParam),
        userId: this.userId,
        sid: this.userSid
      })
    },
    movePages (index) {
      const newStartRow = this.startRow + index
      if (newStartRow >= 0) {
        this.startRow = newStartRow
      }
      const offset = this.startRow > 0 ? this.startRow * this.rowsPerPage : 0
      this.wsParam = {
        limit: this.rowsPerPage,
        offset: offset
      }
      this.sendApi()
    },
    updateUser (id) {
      this.wsParam = this.users[id]
      this.wsParam.event = 'userUpdate'
      this.sendApi()
      this.wsParam = {}
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
        if (this.onMessage.error) {
          // localStorage.clear()
          // location.href = '/'
        }
        switch (this.onMessage.event) {
          case 'api':
            this.sendApi()
            break
          case '/serviceUsers':
            this.user = JSON.parse(this.onMessage.userData)
            this.users = JSON.parse(this.onMessage.users)
            if (this.loading) {
              this.loading = false
            }
            setTimeout(() => {
              this.startApps = false
            }, 1000)
            break
          default:
            break
        }
      }

      this.connection.onclose = (e) => {
        if (this.checkConnected) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
          this.connectNew()
        } else {
          console.log('Socket is closed. beforeUnmount')
        }
      }
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    }
  }
}
</script>
