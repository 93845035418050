import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StatisticView from '../views/StatisticView.vue'
import OrdersView from '../views/OrdersView.vue'
import OffersView from '../views/OffersView.vue'
import PayOutsView from '../views/PayOutsView.vue'
import LoginView from '../views/LoginView.vue'
import ServiceStartView from '@/views/ServiceStartView.vue'
import ServiceStatistic from '@/views/ServiceStatistic.vue'
import ServiceOrders from '@/views/ServiceOrders.vue'
import ServicePayouts from '@/views/ServicePayouts.vue'
import ServiceEngine from '@/views/ServiceEngine.vue'
import ServiceUsers from '@/views/ServiceUsers.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true
    },
    component: HomeView
  },
  {
    path: '/statistic',
    name: 'statistic',
    meta: {
      authRequired: true
    },
    component: StatisticView
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      authRequired: true
    },
    component: OrdersView
  },
  {
    path: '/offers',
    name: 'offers',
    meta: {
      authRequired: true
    },
    component: OffersView
  },
  {
    path: '/payouts',
    name: 'payouts',
    meta: {
      authRequired: true
    },
    component: PayOutsView
  },
  {
    path: '/serviceDash',
    name: 'service',
    meta: {
      authRequired: true
    },
    component: ServiceStartView
  },
  {
    path: '/serviceStatistic',
    name: 'serviceStatistic',
    meta: {
      authRequired: true
    },
    component: ServiceStatistic
  },
  {
    path: '/serviceOrders',
    name: 'serviceOrders',
    meta: {
      authRequired: true
    },
    component: ServiceOrders
  },
  {
    path: '/servicePayouts',
    name: 'servicePayouts',
    meta: {
      authRequired: true
    },
    component: ServicePayouts
  },
  {
    path: '/serviceEngine',
    name: 'serviceEngine',
    meta: {
      authRequired: true
    },
    component: ServiceEngine
  },
  {
    path: '/serviceUsers',
    name: 'serviceUsers',
    meta: {
      authRequired: true
    },
    component: ServiceUsers
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.authRequired)) {
    console.log('authRequire')
    const userData = localStorage.getItem('uid')
    if (!userData) {
      console.log('no userData')
      next({ name: 'login' })
    } else {
      console.log('userData true')
      next()
    }
  } else {
    console.log('next')
    next()
  }
})

export default router
