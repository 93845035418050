<template>
  <!-- Begin page -->
    <div v-if="loading" class="loading-overlay">
        <div class="loading-text">Загрузка...</div>
    </div>
        <HeaderTemplate :user="user" :dashName="dashName" :startApps="startApps"/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
          <div class="container-fluid">
              <!-- start page title -->
              <div class="row">
                  <div class="col-12">
                      <div class="page-title-box d-flex align-items-center justify-content-between">
                          <h4 class="mb-0 font-size-18">Выплаты</h4>

                          <div class="page-title-right">
                              <ol class="breadcrumb m-0">
                                  <li class="breadcrumb-item"><a href="javascript: void(0);">{{ dashName }}</a></li>
                                  <li class="breadcrumb-item active">Выплаты</li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- end page title -->

              <div class="row">
                  <div class="col-md-12">
                      <div class="card">
                          <div class="card-body">
                              <div class="clearfix">
                                  <div class="float-left">
                                      <h4><b>{{ user.login }}</b></h4>
                                  </div>
                                  <div class="float-right">
                                      <h4 class="m-0 d-print-none"></h4>
                                  </div>
                              </div>
                              <div class="row mt-4">
                                  <div class="col-6">
                                      <h6 class="font-weight-bold">Доступно:</h6>

                                      <address class="line-h-24">
                                          <b>{{ user.balance || 0 }} $</b>
                                      </address>
                                  </div><!-- end col -->
                                  <div class="col-6">
                                      <div class="mt-3 float-right">
                                        <button @click="showAlert()" type="button" class="btn btn-info btn-sm waves-effect waves-light sa-long-content">Заказать</button>
                                      </div>
                                  </div><!-- end col -->
                              </div>
                              <!-- end row -->

                              <div class="row">
                                  <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead>
                                            <tr>
                                              <th>ИНФОРМАЦИЯ</th>
                                              <th>СУММА</th>
                                              <th>АДРЕС</th>
                                              <th>СТАТУС</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(val, key) in payoutsRowList" :key="key">
                                              <th>
                                                ID {{val.id}}
                                                <br>
                                                <small v-text="val.status > 0 ? val.createdAt + ' (' + val.updatedAt + ')' : val.createdAt"></small>
                                              </th>
                                              <th>{{val.amount}} $</th>
                                              <td>{{val.wallet}}</td>
                                              <td class="table-user">
                                                <img
                                                    :src="val.status > 0 ? '/assets/images/animated/money.gif' : '/assets/images/animated/hourglass.gif'"
                                                    :alt="val.status > 0 ? 'оплачен' : 'ожидание'"
                                                    class="mr-2 avatar-xs rounded-circle">
                                              </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-center">
                                            <li class="page-item">
                                                <button class="page-link waves-effect" @click="movePages(-1)">◄</button>
                                            </li>
                                            <li class="page-item"><button class="page-link waves-effect" @click="movePages(-1)">{{startRow === 0 ? '' : startRow}}</button></li>
                                            <li class="page-item active"><button class="page-link waves-effect" >{{startRow + 1}}</button></li>
                                            <li class="page-item"><button class="page-link waves-effect" @click="movePages(1)">{{startRow + 2}}</button></li>
                                            <li class="page-item">
                                                <button class="page-link waves-effect" @click="movePages(1)">►</button>
                                            </li>
                                        </ul>
                                    </nav>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-6">
                                      <div class="clearfix pt-5">
                                          <h6 class="text-muted">Але:</h6>

                                          <small>
                                              Не копи деньги на балансе! Это не копилка. Выводи по мере роста баланса.
                                          </small>
                                      </div>

                                  </div>
                                  <div class="col-6">
                                      <div class="float-right">
                                          <p><b>Вывел:</b> {{ user.allPayOuts || 0 }} $</p>
                                      </div>
                                      <div class="clearfix"></div>
                                  </div>
                              </div>

                              <div class="d-print-none my-4">
                                  <div class="text-right">
                                      <a href="javascript:window.print()" class="btn btn-primary waves-effect waves-light"><i class="fa fa-print m-r-5"></i> Печать</a>
                                  </div>
                              </div>
                          </div>
                      </div> <!-- end card-->
                  </div> <!-- end col -->
              </div>
              <!-- end row -->
          </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2020 © Xeloro.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            Design & Develop by Myra
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <!-- end main content-->

<!-- END layout-wrapper -->

<!-- Overlay-->
<div class="menu-overlay"></div>
<!-- SWALL -->
<div v-if="alertWin" class="swal2-container swal2-center swal2-backdrop-show" style="overflow-y: auto;"><div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" class="swal2-popup swal2-modal swal2-show" tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true" style="display: grid;">
  <button @click="closeAlert()" type="button" class="swal2-close" aria-label="Close this dialog" style="display: flex;">×</button><ul class="swal2-progress-steps" style="display: none;"></ul><div class="swal2-icon" style="display: none;"></div><img class="swal2-image" style="display: none;"><h2 class="swal2-title" id="swal2-title" style="display: block;"><i>Запрос выплаты</i></h2><div class="swal2-html-container" id="swal2-html-container" style="display: block;"><div class="card">
                  <div class="card-body">
                      <h5 class="mt-0">
                        {{ userBTC.length ? alertText[0] : alertText[1] }}
                      </h5>
                      <p class="text-muted font-13 mb-4" v-text="user.balance > minPayoutSum ? 'Максимально доступная сумма для выплаты вставлена в поле, при необходимости измени ее самостоятельно' : 'Твой баланс еще слишком мал для заказа выплаты((.'"></p>
                      <div class="form-group row">
                              <div class="col-sm-12">
                                  <input v-model="userBTC" class="form-control" type="text" id="btc" :readonly="userBTC.length" placeholder="BTC адрес">
                              </div>
                              <div class="col-sm-12">
                                  <input v-model="payoutSum" class="form-control" type="number" id="balance" >
                              </div>
                          </div>
                          <button v-if="payoutSum >= minPayoutSum" @click="createPayout()" class="btn btn-primary btn-block  px-4">Запросить выплату</button>
                  </div>
              </div></div><input id="swal2-input" class="swal2-input" style="display: none;"><input type="file" class="swal2-file" style="display: none;"><div class="swal2-range" style="display: none;"><input type="range"><output></output></div><select id="swal2-select" class="swal2-select" style="display: none;"></select><div class="swal2-radio" style="display: none;"></div><label class="swal2-checkbox" style="display: none;"><input type="checkbox" id="swal2-checkbox"><span class="swal2-label"></span></label><textarea id="swal2-textarea" class="swal2-textarea" style="display: none;"></textarea><div class="swal2-validation-message" id="swal2-validation-message" style="display: none;"></div><div class="swal2-actions" style="display: flex;"><div class="swal2-loader"></div>
              <button @click="closeAlert()" type="button" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;"><i class="mdi mdi-thumb-up-outline"></i> Закрыть</button>
              <button type="button" class="swal2-deny swal2-styled" aria-label="" style="display: none;">No</button>
              <button type="button" class="swal2-cancel swal2-styled" aria-label="" style="display: none;">Cancel</button>
              </div>
              <div class="swal2-footer" style="display: none;"></div>
              <div class="swal2-timer-progress-bar-container">
                <div class="swal2-timer-progress-bar" style="display: none;"></div>
              </div></div></div>
</template>

<script>

import wsMixin from '../mixins/websocket.js'
import HeaderTemplate from '@/components/HeaderTemplate.vue'
import Swal from 'sweetalert2'
// import md5 from 'md5'

export default {
  name: 'PayOutsView',
  mixins: [wsMixin],
  components: {
    HeaderTemplate
  },
  data () {
    return {
      startApps: true,
      loading: true,
      onMessage: null,
      user: {},
      userId: localStorage.getItem('uid'),
      userSid: localStorage.getItem('sid'),
      userBTC: '',
      alertText: ['Я помню адрес, на который последний раз была заказана выплата. Он вставлен в поле кошелька. Чтобы изменить его, пиши админу.', 'Я запомню адрес, на который будет заказана выплата. В следующий раз я вставлю его для удобства.'],
      allPayouts: [],
      payoutsRowList: [],
      startRow: 0,
      rowsPerPage: 10,
      createScript: null,
      orderBy: '',
      wsParam: { orderBy: false, where: false, ascDesc: false, limit: false, offset: false, event: false, payoutSum: false, payoutAddress: false },
      checkConnected: true,
      alertWin: false,
      minPayoutSum: 10,
      payoutSum: 0
    }
  },
  created () {
    this.connectNew()
  },
  mounted () {
    setTimeout(() => {
      this.sendApi()
    }, 200)
  },
  beforeUnmount () {
    this.checkConnected = false
    this.connection.close()
  },
  watch: {
    wsParam: function (val) {
      this.sendApi()
    }
  },
  methods: {
    saerror () {
      Swal.fire({
        type: 'error',
        title: 'УПС...',
        text: 'Да неее, маловато деняк еще!',
        confirmButtonClass: 'btn btn-confirm mt-2',
        footer: '<a href="">Как заработать?</a>'
      })
    },
    showAlert () {
      if (!this.alertWin) {
        this.alertWin = true
      }
    },
    closeAlert () {
      if (this.alertWin) {
        this.alertWin = false
      }
    },
    createPayout () {
      if (this.user.balance < this.payoutSum || this.userBTC.length < 25) {
        this.saerror()
        return false
      }
      this.wsParam.event = 'payoutCreate'
      this.wsParam.payoutSum = this.payoutSum
      this.wsParam.payoutAddress = this.userBTC
      // localStorage.setItem('btc', this.userBTC)
      this.sendApi()
      this.closeAlert()
    },
    sendApi () {
      this.sendMessage({
        event: '/payouts',
        param: JSON.stringify(this.wsParam),
        userId: this.userId,
        sid: this.userSid
      })
      this.wsParam.event = false
      this.wsParam.payoutSum = false
      this.wsParam.payoutAddress = false
    },
    movePages (index) {
      const newStartRow = this.startRow + index
      if (newStartRow >= 0) {
        this.startRow = newStartRow
      }
      const offset = this.startRow > 0 ? this.startRow * this.rowsPerPage : 0
      this.wsParam.limit = this.rowsPerPage
      this.wsParam.offset = offset
      this.sendApi()
    },
    connectNew () {
      console.log('Starting connection to WebSocket Server')
      this.urlConnect = (localStorage.getItem('sid') && localStorage.getItem('qid')) ? this.wsConnect + '/?sid=' + localStorage.getItem('sid') + '&qid=' + localStorage.getItem('qid') + this.rndGid : this.wsConnect + '/?sid=' + this.sid
      this.connection = new WebSocket(this.urlConnect)

      this.connection.onopen = (event) => {
        this.connectFlag = true
        console.log('Successfully connected to the echo websocket server...')
      }

      this.connection.onmessage = (event) => {
        this.onMessage = JSON.parse(event.data)
        if (this.onMessage.error) {
          localStorage.clear()
          location.href = '/'
        }
        switch (this.onMessage.event) {
          case 'api':
            this.sendApi()
            break
          case '/payouts':
            this.user = JSON.parse(this.onMessage.userData)
            this.payoutSum = this.user.balance
            this.allPayouts = this.onMessage.payoutsList
            this.payoutsRowList = this.onMessage.payoutsList
            this.userBTC = this.user.wallet || ''
            if (this.loading) {
              this.loading = false
            }
            setTimeout(() => {
              this.startApps = false
            }, 1000)
            break
          default:
            break
        }
      }

      this.connection.onclose = (e) => {
        if (this.checkConnected) {
          console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
          this.connectNew()
        } else {
          console.log('Socket is closed. beforeUnmount')
        }
      }
    },
    sendMessage (message) {
      if (!this.connection.readyState) {
        setTimeout(() => {
          this.sendMessage(message)
        }, 1000)
      } else {
        this.connection.send(JSON.stringify(message))
      }
    }
  }
}
</script>
